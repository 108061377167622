import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import MenuLeft from "../components/Header/MenuLeft";
import { User } from "../models/user";
import ForgotPassword from "../pages/ForgotPassword";
import ForgotPasswordVerify from "../pages/ForgotPasswordVerify";
import LoginScan from "../pages/LoginScan";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpVerify from "../pages/SignUpVerify";

interface AuthRoutesProps {
  user: User;
}

const Stack = createStackNavigator();

const AuthRoutes: React.FC<AuthRoutesProps> = ({ user }) => {
  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName={user && user.status == 1 ? "SignUpVerify" : "SignIn"}
      >
        <Stack.Screen
          name="SignIn"
          component={SignIn}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ForgotPassword"
          component={ForgotPassword}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ForgotPasswordVerify"
          component={ForgotPasswordVerify}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SignUp"
          component={SignUp}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SignUpVerify"
          component={SignUpVerify}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="LoginScan"
          component={LoginScan}
          options={{
            headerTitleStyle: { display: "flex" },
            title: "Escanear Qrcode",
            headerShown: true,
            headerLeft: (props) => <MenuLeft />,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AuthRoutes;
