import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';
import { ActionColor, colorBlack, colorWhite } from '../../utils/colors';

export const Container = styled.SafeAreaView`
  flex: 1;
  padding: ${normalizeLimit(14)}px ${normalizeLimit(8)}px;
  flex-direction: column;
  justify-content: space-between;
`;

export const MessageContainer = styled.ScrollView`
  flex: 1;
`;

export const BallonContainer = styled.View`
  justify-content: center;
  align-items: ${({ isSupport }: { isSupport: boolean }) => isSupport ? 'flex-start' : 'flex-end'};
  margin: ${normalizeLimit(4)}px 0;
`;

export const BallonMessage = styled.View`
  border-radius: ${({ isSupport }: { isSupport: boolean }) => isSupport ? '18px 18px 18px 0' : '18px 18px 0 18px'} ;
  padding: ${normalizeLimit(12)}px ${normalizeLimit(16)}px;
  background-color: ${({ isSupport }: { isSupport: boolean }) => isSupport ? colorWhite : ActionColor};
  max-width: 60%;

`;

export const BallonText = styled.Text`
  font-size: ${normalizeLimit(16)}px;
  color: ${({ isSupport }: { isSupport: boolean }) => isSupport ? colorBlack : colorWhite}
`;

export const InputContainer = styled.View`
overflow: hidden;
  position: relative;
  background-color: #dedede;
  margin-top: ${normalizeLimit(14)}px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  border: none;
`;

export const Input = styled.TextInput`
  flex: 1;
  padding: ${normalizeLimit(14)}px;
  font-size: ${normalizeLimit(16)}px;
`;

export const SendButton = styled.Pressable`
  position: absolute;
  background-color: ${ActionColor};
  right: 0;
  padding: 12px 32px;
  border: none;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
`;