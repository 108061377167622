import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useState } from "react";
import { ActivityIndicator } from "react-native";
import { Button } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { MaskedTextInput } from "react-native-mask-text";
import * as Yup from "yup";
import MachineSelectedView from "../../components/MachineSelectedView";
import MyModal from "../../components/MyModal";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { CREDIT_CARD, DEBIT_CARD, PIX, VOUCHER_CARD } from "../../image";
import { BasketItem } from "../../models/basketItem";
import { MachineIdentification } from "../../models/machine";
import { TotemRoutesStackTypes } from "../../routes/totem.routes";
import api from "../../services/api";
import { ActionColor, colorRed } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { formatCurrency } from "../../utils/formatNumber";
import toCapitalize from "../../utils/toCapitalize";
import { isValidCPF, isValidPhoneNumber } from "../../utils/valid";
import {
  BasketInfo,
  CardImage,
  CenteredView,
  Container,
  ContainerInput,
  ContainerPayment,
  FormContainer,
  InputLabel,
  InputTextError,
  ItemImagePix,
  PaymentCard,
  PaymentCardText,
  PaymentTitle,
  ProductContainer,
  ProductContent,
  ProductDelete,
  ProductImage,
  ProductInfoContainer,
  ProductPrice,
  ProductTitle,
  PurchaseTotal,
  ResumeTitle,
  WrapperInput,
} from "./styles";

type RouteParamList = {
  machine: MachineIdentification;
  total: number;
  items: BasketItem[];
};

interface PaymentPixResponse {
  payment: { payment_id: string; qr_code: string; qr_code_url: string };
}

interface FormValuePix {
  cpf?: string;
  phone?: string;
}

const PixFormValidationSchema = Yup.object().shape({
  cpf: Yup.string()
    .test("test-number", "Insira um CPF válido!", (value) => isValidCPF(value))
    .required("Insira o seu CPF"),
  phone: Yup.string()
    .test("test-number", "Insira um Número válido!", (value) =>
      isValidPhoneNumber(value)
    )
    .required("Insira seu número de telefone"),
});

const PaymentTotem: React.FC = () => {
  const navigation = useNavigation<TotemRoutesStackTypes>();
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  let updatedCart: BasketItem[];
  const { machine } = route.params;
  const { user } = useAuth();
  const [paymentMethodSelected, setPaymentMethodSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPixForm, setShowPixForm] = useState(false);
  const [items, setItems] = useState<BasketItem[] | []>(
    JSON.parse(JSON.stringify(route.params.items))
  );
  let total = getTotal();

  function getTotal() {
    return items.reduce((acc, cur) => {
      acc += cur.price * cur.quantity;
      return acc;
    }, 0);
  }

  async function saveCartItems(data: BasketItem[]) {
    try {
      setLoading(true);
      const params = {
        machine_id: machine.id,
        items: data,
      };
      api.post("/cart", { ...params });
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  async function onSubmitPixHandler({ cpf, phone }: FormValuePix) {
    try {
      setLoading(true);
      if (!cpf || !phone) {
        AlertOS({
          title: "Pagamento PIX",
          message:
            "Para prosseguir com o pagamento Pix, você deve informar o CPF e Número de telefone",
          toastType: "info",
        });
        return;
      }
      setShowPixForm(false);
      const phone_number = {
        country_code: "55",
        number: phone.slice(2),
        area_code: phone.slice(0, 2),
      };
      const products = items.map((item) => {
        return {
          product_id: item.id,
          unit_price: item.price,
          description: item.name,
          quantity: item.quantity,
        };
      });
      const { data } = await api.post<PaymentPixResponse>("/payment/pix", {
        machine_id: machine.id,
        products_purchase: products,
        cpf,
        phone_number,
        save: false,
      });
      const { payment } = data;
      navigation.navigate("Pix", {
        items,
        total,
        qrCodeString: payment.qr_code,
        qrCodeImage: payment.qr_code_url,
        paymentId: payment.payment_id,
        machine,
      });
    } catch (error) {
      AlertOS({
        title: "Pagamento não realizado",
        message: "Não foi possível finalizar o pagamento. Tente novamente",
        toastType: "info",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleDeleteItem(item: BasketItem) {
    const productId = item.id;
    updatedCart = items
      .map((cartItem) => {
        if (cartItem.id === productId) {
          cartItem.quantity -= 1;
        }
        return cartItem;
      })
      .filter((cartItem) => cartItem.quantity > 0);
    saveCartItems(updatedCart);
    setItems(updatedCart);
  }

  function handlePayments(
    paymentScreen: "DebitCard" | "CreditCard" | "VoucherCard"
  ) {
    if (!total) return;
    navigation.navigate(paymentScreen, { machine, items, total });
  }

  return (
    <Container>
      <MyModal visible={showPixForm}>
        <CenteredView>
          <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
            <Formik
              initialValues={{
                cpf: user.cpf,
                phone: user.phone?.substring(2),
              }}
              validationSchema={PixFormValidationSchema}
              validateOnChange={true}
              onSubmit={(values) => {
                onSubmitPixHandler({ cpf: values.cpf, phone: values.phone });
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <FormContainer>
                  <PaymentTitle>
                    Informe seu CPF e Número de telefone para prosseguir com o
                    pagamento Via Pix
                  </PaymentTitle>
                  <WrapperInput>
                    <InputLabel>Telefone</InputLabel>
                    <ContainerInput>
                      <MaterialIcons
                        color={ActionColor}
                        name="phone"
                        size={24}
                        style={{
                          marginRight: 14,
                        }}
                      />
                      <MaskedTextInput
                        style={{
                          flex: 1,
                          fontSize: 18,
                          paddingVertical: 8,
                        }}
                        mask="(99) 99999-9999"
                        placeholder="(99) 99999-9999"
                        placeholderTextColor={"rgb(134, 147, 158)"}
                        keyboardType="phone-pad"
                        onChangeText={(text: string, rawText: string): void => {
                          handleChange("phone")(rawText);
                        }}
                        onBlur={handleBlur("phone")}
                        maxLength={15}
                        value={values.phone}
                        returnKeyType="next"
                        autoCapitalize="none"
                        autoCorrect={false}
                        onSubmitEditing={() => handleSubmit()}
                        autoComplete={undefined}
                      />
                    </ContainerInput>
                    <InputTextError
                      style={{
                        paddingBottom: dinamicPadding(
                          errors.phone,
                          touched.phone
                        ),
                      }}
                    >
                      {errors.phone}
                    </InputTextError>
                  </WrapperInput>
                  <WrapperInput>
                    <InputLabel>Cpf</InputLabel>
                    <ContainerInput>
                      <MaterialIcons
                        color={ActionColor}
                        name="person"
                        size={24}
                        style={{
                          marginRight: 14,
                        }}
                      />
                      <MaskedTextInput
                        style={{
                          flex: 1,
                          fontSize: 18,
                          paddingVertical: 8,
                        }}
                        mask="999.999.999-99"
                        keyboardType="numeric"
                        placeholder="999.999.999-99"
                        placeholderTextColor={"rgb(134, 147, 158)"}
                        onChangeText={(text: string, rawText: string) => {
                          handleChange("cpf")(rawText);
                        }}
                        value={values.cpf}
                        maxLength={14}
                        autoCapitalize="none"
                        returnKeyType="send"
                        autoCorrect={false}
                        onSubmitEditing={() => handleSubmit()}
                        autoComplete={undefined}
                      />
                    </ContainerInput>
                    <InputTextError
                      style={{
                        paddingBottom: dinamicPadding(errors.cpf, touched.cpf),
                      }}
                    >
                      {errors.cpf}
                    </InputTextError>
                  </WrapperInput>
                  <Button
                    title="Concluído"
                    loading={loading}
                    onPress={() => handleSubmit()}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                  <Button
                    title="Fechar"
                    type="clear"
                    loading={loading}
                    onPress={() => setShowPixForm(false)}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                </FormContainer>
              )}
            </Formik>
          </KeyboardAwareScrollView>
        </CenteredView>
      </MyModal>
      <MachineSelectedView machine={machine} />
      <BasketInfo>
        <ResumeTitle>Resumo da compra</ResumeTitle>
        {items.map((item: BasketItem) => (
          <ProductContainer key={item.id}>
            <ProductInfoContainer>
              <ProductImage source={{ uri: item.image }} />
              <ProductContent>
                <ProductTitle ItemTitle>{toCapitalize(item.name)}</ProductTitle>
                <ProductPrice>
                  {item.quantity}x {formatCurrency(item.price / 100)}
                </ProductPrice>
              </ProductContent>
            </ProductInfoContainer>
            <ProductDelete>
              <MaterialIcons
                onPress={() => handleDeleteItem(item)}
                name="delete"
                color={colorRed}
                size={24}
              />
            </ProductDelete>
          </ProductContainer>
        ))}
        <PurchaseTotal>Total {formatCurrency(total / 100)}</PurchaseTotal>
      </BasketInfo>

      <ContainerPayment nerPayment style={{ zIndex: 10 }}>
        <PaymentTitle>Formas de pagamento</PaymentTitle>
        <PaymentCard
          disabled={loading}
          onPress={() => {
            setPaymentMethodSelected("debit");
            handlePayments("DebitCard");
          }}
          selected={paymentMethodSelected.toLowerCase() == "debit"}
        >
          <CardImage source={DEBIT_CARD} />
          <PaymentCardText>Cartão Débito</PaymentCardText>
        </PaymentCard>
        <PaymentCard
          disabled={loading}
          onPress={() => {
            setPaymentMethodSelected("credit");
            handlePayments("CreditCard");
          }}
          selected={paymentMethodSelected.toLowerCase() == "credit"}
        >
          <CardImage source={CREDIT_CARD} />
          <PaymentCardText>Cartão Crédito</PaymentCardText>
        </PaymentCard>
        <PaymentCard
          disabled={loading}
          onPress={() => {
            setPaymentMethodSelected("voucher");
            handlePayments("VoucherCard");
          }}
          selected={paymentMethodSelected.toLowerCase() == "voucher"}
        >
          <CardImage source={VOUCHER_CARD} />
          <PaymentCardText>Voucher</PaymentCardText>
        </PaymentCard>
        <PaymentTitle>Outras</PaymentTitle>

        <PaymentCard
          disabled={loading}
          onPress={() => {
            setPaymentMethodSelected("pix");
            if (!total) return;
            setShowPixForm(true);
          }}
          selected={paymentMethodSelected.toLowerCase() == "pix"}
        >
          <ItemImagePix source={PIX} />
          {loading ? (
            <>
              <PaymentCardText>Pagamento Pix. Aguarde...</PaymentCardText>
              <ActivityIndicator size="small" color={ActionColor} />
            </>
          ) : (
            <PaymentCardText>Pagamento Pix</PaymentCardText>
          )}
        </PaymentCard>
      </ContainerPayment>
    </Container>
  );
};

export default PaymentTotem;
