import styled from 'styled-components/native';
import { colorGray, PixColor } from '../../utils/colors';
import { normalizeLimit } from '../../utils/normalizeLimit';

export const Container = styled.ScrollView`
  flex: 1;
  margin: 0;
  background: #FFFFFF;
  padding: ${normalizeLimit(20)}px ${normalizeLimit(15)}px;
  color: ${colorGray};
`;

export const PixContent = styled.View`
  margin: ${normalizeLimit(16)}px 0;
`;

export const ImageContainer = styled.View`
  justify-content: center;
  align-items: center;
  margin: ${normalizeLimit(24)}px;
`;

export const PixImage = styled.Image`
  width: ${normalizeLimit(210)}px;
  height: ${normalizeLimit(210)}px;
`;

export const TextList = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: ${normalizeLimit(8)}px;
`;

export const NumberText = styled.Text`
  font-size: ${normalizeLimit(16)}px;
  margin-right: ${normalizeLimit(8)}px;
  font-weight: bold;

`;

export const Title = styled.Text`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${PixColor};
  margin: ${normalizeLimit(24)}px 0 ${normalizeLimit(8)}px 0;
`;

export const Message = styled.Text`
  font-size: ${normalizeLimit(16)}px;
  text-align: center;
  color: ${colorGray};
`;

export const QRCodeImage = styled.Image`
  width: ${normalizeLimit(250)}px;
  height: ${normalizeLimit(250)}px;
`;

export const PixCodeContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  margin: ${normalizeLimit(20)}px 0;
  padding: ${normalizeLimit(24)}px;
  border: 2px dashed ${colorGray};
  border-radius: 16px;
`;

export const PixCodeText = styled.Text`
  flex: 1;
  font-size: 18px;
  color: ${colorGray}
`;