import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import MenuLeft from "../components/Header/MenuLeft";
import Address from "../pages/Address";
import CreditCard from "../pages/CreditCard";
import DashboardRoutes from "./dashboard.routes";
import ChatSupport from "../pages/ChatSupport";
const Stack = createStackNavigator();

const AppRoutes: React.FC = () => (
  <NavigationContainer>
    <Stack.Navigator
      initialRouteName="DashboardRoutes"
      screenOptions={{ headerMode: "screen", headerShown: false }}
    >
      <Stack.Screen
        name="DashboardRoutes"
        component={DashboardRoutes}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CreditCard"
        component={CreditCard}
        options={{
          headerTitleStyle: { display: "flex" },
          title: "Cartão de Crédito",
          headerShown: true,
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="Address"
        component={Address}
        options={{
          headerTitleStyle: { display: "flex" },
          title: "Endereço",
          headerShown: true,
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="ChatSupport"
        component={ChatSupport}
        options={{
          title: "Chat Suporte",
          headerShown: true,
          headerLeft: (props) => <MenuLeft />,
        }}
      />
    </Stack.Navigator>
  </NavigationContainer>
);

export default AppRoutes;
