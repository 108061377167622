export const IMGMACHINE = require('./machine.jpg');
export const CARD_IMAGE = require('./card.png');
export const IMGPRODUCT = require('./product.jpg');
export const APPLOGO = require('./applogo.png');
export const IMGADDRESS = require('./address.png');
export const PIX = require('./pix.png');
export const PIX_IMAGE = require('./pagamento_pix.png');
export const PIX_IMAGE_ERROR = require('./pagamento_pix_error.png');
export const LOCATION_IMAGE = require('./location.png');
export const NO_ITENS = require('./noitens.png');
export const COUPON = require('./coupon.png');
export const MAQUININHA = require('./maquininha.png');
export const MAQUININHA_ERROR = require('./maquininha_error.png');
export const MAQUININHA_APPROVED = require('./maquininha_approved.png');
export const MAQUININHA_TIMEOUT = require('./maquininha_timeout.png');
export const CREDIT_CARD = require('./credit_card.png');
export const DEBIT_CARD = require('./debit_card.png');
export const VOUCHER_CARD = require('./voucher_card.png');
export const PAYMENT_CONFIRMED = require('./payment_confirmed.png');
export const PAYMENT_ERROR = require('./payment_error.png');

export const creditcardsimg = (brand: string) => {
    if (brand == null || brand == undefined) {
        brand = 'noemiter'
    }

    switch (brand.toLocaleLowerCase()) {
        case 'master': { return require('./master.png'); }
        case 'mastercard': { return require('./master.png'); }
        case 'visa': { return require('./visa.png'); }
        case 'elo': { return require('./elo.png'); }
        case 'hipercard': { return require('./hipercard.png'); }
        case 'amex': { return require('./amex.png'); }
        default: { return require('./noemiter.png'); }
    }
};