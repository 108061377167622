import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Button } from "react-native-elements";
import { MAQUININHA_ERROR } from "../../../image";
import BasketInfo from "../../../components/BasketInfo";
import { BasketItem } from "../../../models/basketItem";
import { TotemRoutesStackTypes } from "../../../routes/totem.routes";
import {
  CardContent,
  CardImage,
  Container,
  ImageContainer,
  Message,
  Title,
} from "./styles";

type RouteParamList = {
  total: number;
  items: BasketItem[];
};

const CardTimeout: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const TEN_SECONDS = 10 * 1000;
  const { total, items } = route.params;
  const navigation = useNavigation<TotemRoutesStackTypes>();

  useEffect(() => {
    const timeout = setTimeout(async () => {
      navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] });
    }, TEN_SECONDS);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container>
      <CardContent>
        <ImageContainer>
          <CardImage source={MAQUININHA_ERROR} />
        </ImageContainer>
        <Title>Não foi possível realizar o pagamento</Title>
        <Message>Tente novamente!</Message>
      </CardContent>
      <BasketInfo items={items} total={total} />
      <Button
        onPress={() =>
          navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] })
        }
        title="Fazer pedido"
      />
    </Container>
  );
};

export default CardTimeout;
