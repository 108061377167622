import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';
import { ActionColor, colorGray } from '../../utils/colors';


export const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: ${normalizeLimit(8)}px;
`;

export const ResumeContainer = styled.View`
  padding: ${normalizeLimit(14)}px 0;
`;

export const ResumeTitle = styled.Text`
  font-size: ${normalizeLimit(16)}px;
  margin-bottom: ${normalizeLimit(14)}px;
  font-weight: bold;
  color: #333;
`;

export const ProductsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${normalizeLimit(8)}px;
`;

export const ProductInfo = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ProductQuantity = styled.Text`
  margin-right: ${normalizeLimit(16)}px;
  font-weight: bold;
  color: ${ActionColor};
`;

export const ProductName = styled.Text`
  color: ${colorGray};
`;

export const ProductTotal = styled.Text`
  font-weight: bold;
  color: #333333;
`;

export const TotalContent = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top-color: rgba(0,0,0,.2);
  border-top-width: 1px;
  padding: ${normalizeLimit(16)}px 0 ;
`;

export const TotalTitle = styled.Text`
  margin-left: 0px;
  font-weight: bold;
  color: #333333;
`;

export const TotalValue = styled.Text`
  margin-left: 0px;
  font-weight: bold;
  color: #333333;
`;
