import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import { Button } from "react-native-elements";
import BasketInfo from "../../../components/BasketInfo";
import { MAQUININHA } from "../../../image";
import { BasketItem } from "../../../models/basketItem";
import { MachineIdentification } from "../../../models/machine";
import { TotemRoutesStackTypes } from "../../../routes/totem.routes";
import api from "../../../services/api";
import { ActionColor, colorRed } from "../../../utils/colors";
import {
  CardContent,
  CardImage,
  Container,
  ImageContainer,
  Message,
  Title,
} from "./styles";

type RouteParamList = {
  machine: MachineIdentification;
  total: number;
  items: BasketItem[];
};

interface Product {
  product_id: string;
  quantity: number;
}

interface createOrderBody {
  machine_id: string;
  order_items: Product[];
  payment_type: string;
  installments?: number;
}

const DebitCard: React.FC = () => {
  const FIVE_MINUTES = 60 * 1000 * 5;
  const MSG_LOADING_PAYMENT_TITLE =
    "Um momento, estamos processando seu pagamento...";
  const MSG_LOADING_PAYMENT = "Aguarde...";
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation<TotemRoutesStackTypes>();
  const { total, items, machine } = route.params;
  const [loading, setLoading] = useState(true);
  const [timeoutPID, setTimeoutPID] = useState<any>();
  const [intervalPID, setIntervalPID] = useState<any>();

  async function createTotemOrder(body: createOrderBody) {
    try {
      setLoading(true);
      const response = await api.post("/order/totem", { ...body });
      const { payment } = response.data;
      setLoading(false);
      return payment;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  function clearTimers(interval: NodeJS.Timeout, timeout: NodeJS.Timeout) {
    clearInterval(interval);
    clearTimeout(timeout);
  }

  async function handleDebitCard() {
    try {
      let getIntervalPID: NodeJS.Timeout;
      let getTimeoutPID: NodeJS.Timeout;
      const order_items = items.map((product) => ({
        product_id: product.id,
        quantity: product.quantity,
      }));
      const body = {
        machine_id: machine.id,
        order_items,
        payment_type: "debit",
      };
      const data = await createTotemOrder(body);
      const { payment_id } = data;
      getIntervalPID = setInterval(async () => {
        try {
          const response = await api.get(`/payment/${payment_id}`);
          const { status } = response.data.payment;
          if (status.toLowerCase() === "paid") {
            clearTimers(getIntervalPID, getTimeoutPID);
            await api.delete("/cart", { params: { machine_id: machine.id } });
            navigation.navigate("CardConfirmed", { items, total });
          }
          if (status.toLowerCase() === "canceled") {
            clearTimers(getIntervalPID, getTimeoutPID);
            navigation.navigate("CardError", { items, total });
          }
        } catch (error) {
          clearTimers(getIntervalPID, getTimeoutPID);
          navigation.navigate("CardError", { items, total });
        }
      }, 1000);
      getTimeoutPID = setTimeout(() => {
        clearTimers(getIntervalPID, getTimeoutPID);
        navigation.navigate("CardTimeout", { items, total });
      }, FIVE_MINUTES);
      setIntervalPID(getIntervalPID);
      setTimeoutPID(getTimeoutPID);
    } catch (error) {
      clearTimers(intervalPID, timeoutPID);
      navigation.navigate("CardError", { items, total });
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    clearTimers(intervalPID, timeoutPID);
    navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] });
  }

  useEffect(() => {
    handleDebitCard();
  }, []);

  return (
    <Container>
      <CardContent>
        <Title>Débito Selecionado</Title>
        <ImageContainer>
          <CardImage source={MAQUININHA} />
        </ImageContainer>
        <Title>
          {loading ? MSG_LOADING_PAYMENT_TITLE : "Pedido aguardando pagamento"}
        </Title>
        <Message>
          {loading ? (
            <>
              <ActivityIndicator size="small" color={ActionColor} />{" "}
              {MSG_LOADING_PAYMENT}
            </>
          ) : (
            "Insira, aproxime ou deslize o cartão"
          )}
        </Message>
      </CardContent>
      <BasketInfo items={items} total={total} />
      <Button
        buttonStyle={{
          borderColor: colorRed,
        }}
        titleStyle={{
          color: colorRed,
        }}
        title="Cancelar Pedido"
        type="outline"
        onPress={handleCancel}
      />
    </Container>
  );
};

export default DebitCard;
