import { useChat } from "../../contexts/chat";
import { Container, NumberText } from "./styles";

interface MessageNumberProps {
  Icon: React.ComponentType;
}

export default function MessageNumber({ Icon }: MessageNumberProps) {
  const { messageNumber } = useChat();
  return (
    <Container>
      <Icon />
      {messageNumber != 0 && <NumberText>{messageNumber}</NumberText>}
    </Container>
  );
}
