import { MaterialCommunityIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import React, { useEffect, useState } from "react";
import { Button } from "react-native-elements";
import BasketInfo from "../../components/BasketInfo";
import { useAuth } from "../../contexts/auth";
import { PIX_IMAGE } from "../../image";
import { BasketItem } from "../../models/basketItem";
import { MachineIdentification } from "../../models/machine";
import { TotemRoutesStackTypes } from "../../routes/totem.routes";
import api from "../../services/api";
import { ActionColor, colorRed } from "../../utils/colors";
import {
  Container,
  ImageContainer,
  Message,
  NumberText,
  PixCodeContainer,
  PixCodeText,
  PixContent,
  PixImage,
  QRCodeImage,
  TextList,
  Title,
} from "./styles";

type RouteParamList = {
  total: number;
  items: BasketItem[];
  qrCodeString: string;
  qrCodeImage: string;
  paymentId: string;
  machine: MachineIdentification;
};

const Pix: React.FC = () => {
  const TEN_MINUTES = 60 * 1000 * 10;
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation<TotemRoutesStackTypes>();
  const { total, items, qrCodeString, qrCodeImage, paymentId, machine } =
    route.params;
  const { user } = useAuth();
  const [timeoutPID, setTimeoutPID] = useState<any>();
  const [intervalPID, setIntervalPID] = useState<any>();

  async function copyToClipboard() {
    await Clipboard.setStringAsync(qrCodeString);
  }

  function clearTimers(interval: NodeJS.Timeout, timeout: NodeJS.Timeout) {
    clearInterval(interval);
    clearTimeout(timeout);
  }

  async function handlePix() {
    try {
      let getTimeoutPID: NodeJS.Timeout;
      let getIntervalPID: NodeJS.Timeout;
      getIntervalPID = setInterval(async () => {
        try {
          const response = await api.get(`/payment/${paymentId}`);
          const { status } = response?.data.payment;
          if (status.toLowerCase() === "paid") {
            clearTimers(getIntervalPID, getTimeoutPID);
            navigation.navigate("PaymentConfirmed", { items, total });
          }
        } catch (error) {
          clearTimers(getIntervalPID, getTimeoutPID);
          navigation.navigate("PixError", { items, total, machine });
        }
      }, 1000);
      getTimeoutPID = setTimeout(() => {
        clearTimers(getIntervalPID, getTimeoutPID);
        navigation.navigate("PixError", { items, total, machine });
      }, TEN_MINUTES);
      setIntervalPID(getIntervalPID);
      setTimeoutPID(getTimeoutPID);
    } catch (error) {
      clearInterval(intervalPID);
      clearTimeout(timeoutPID);
      clearTimers(intervalPID, timeoutPID);
      navigation.navigate("PixError", { items, total, machine });
    }
  }

  function handleCancel() {
    clearTimers(intervalPID, timeoutPID);
    navigation.navigate("MachineBasket", { machineId: machine.id, machine });
  }

  useEffect(() => {
    handlePix();
  }, []);

  return (
    <Container>
      <PixContent>
        <ImageContainer>
          <PixImage source={PIX_IMAGE} />
        </ImageContainer>
        <Title>FAÇA SEU PAGAMENTO USANDO O PIX</Title>
      </PixContent>
      {user.isTotemAccount ? (
        <>
          <TextList>
            <NumberText>1. </NumberText>
            <Message>Abra o aplicativo do seu banco</Message>
          </TextList>
          <TextList>
            <NumberText>2. </NumberText>
            <Message>Aponte a câmera para o código</Message>
          </TextList>
        </>
      ) : (
        <>
          <TextList>
            <NumberText>1. </NumberText>
            <Message>Abra o aplicativo do seu banco</Message>
          </TextList>
          <TextList>
            <NumberText>2. </NumberText>
            <Message>
              Aponte a câmera para o código ou use o Pix Copia e Cola
            </Message>
          </TextList>
          <PixCodeContainer activeOpacity={0.8} onPress={copyToClipboard}>
            <PixCodeText numberOfLines={1}>{qrCodeString}</PixCodeText>
            <MaterialCommunityIcons
              name="content-copy"
              color={ActionColor}
              size={25}
            />
          </PixCodeContainer>
          <Button onPress={copyToClipboard} title="Copiar código" />
        </>
      )}
      <ImageContainer>
        <QRCodeImage
          source={{
            uri: qrCodeImage,
          }}
        />
      </ImageContainer>
      <BasketInfo items={items} total={total} />
      <Button
        buttonStyle={{
          borderColor: colorRed,
        }}
        titleStyle={{
          color: colorRed,
        }}
        title="Cancelar Pedido"
        type="outline"
        onPress={handleCancel}
      />
    </Container>
  );
};

export default Pix;
