import { MaterialIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React from "react";

import { ActionColor } from "../utils/colors";
import HomeRoute from "./home.routes";
import ProfileRoute from "./profile.routes";
import PurchaseRoute from "./purchase.routes";
import SearchRoute from "./search.routes";
import MessageNumber from "../components/MessageNumber";

const Tab = createBottomTabNavigator();

const DashboardRoutes: React.FC = () => {
  return (
    <Tab.Navigator
      initialRouteName="HomeRoutes"
      screenOptions={{ tabBarActiveTintColor: ActionColor, headerShown: false }}
    >
      <Tab.Screen
        name="HomeRoutes"
        component={HomeRoute}
        options={{
          tabBarLabel: "Início",
          tabBarIcon: ({ focused, color, size }) => {
            return <MaterialIcons name="home" size={30} color={color} />;
          },
        }}
      />
      <Tab.Screen
        name="SearchRoutes"
        component={SearchRoute}
        options={{
          tabBarLabel: "Pesquisar",
          tabBarIcon: ({ color, size }) => {
            return <MaterialIcons name="search" size={30} color={color} />;
          },
        }}
      />
      <Tab.Screen
        name="PurchaseRoutes"
        component={PurchaseRoute}
        options={{
          tabBarLabel: "Compras",
          tabBarIcon: ({ color, size }) => {
            return <MaterialIcons name="assignment" size={30} color={color} />;
          },
        }}
      />
      <Tab.Screen
        name="ProfileRoutes"
        component={ProfileRoute}
        options={{
          tabBarLabel: "Perfil",
          tabBarIcon: ({ color, size }) => {
            return (
              <MessageNumber
                Icon={() => (
                  <MaterialIcons name="person" size={30} color={color} />
                )}
              />
            );
          },
        }}
      />
    </Tab.Navigator>
  );
};

export default DashboardRoutes;
