import {
  Image,
  ImageContainer,
  ItemContainer,
  ItemQuantify,
  ItemTitle,
  Price,
  Product,
  Quantity,
} from "./styles";

import InputSpinner from "react-native-input-spinner";

import { ActionColor, colorWhite } from "../../utils/colors";
import { formatCurrency } from "../../utils/formatNumber";
import { normalizeLimit } from "../../utils/normalizeLimit";
import { BasketItem } from "../../models/basketItem";

interface ProductItemProps {
  id: string;
  maxQuantity: number;
  image: string;
  name: string;
  price: number;
  quantity: number;
  cartItems: BasketItem[];
  updateCartItems: (items: BasketItem[]) => void;
}

export default function ProductItem({
  id,
  maxQuantity,
  image,
  name,
  price,
  quantity,
  cartItems,
  updateCartItems,
}: ProductItemProps) {
  function updateCartItem({ id, name, image, price, quantity }: BasketItem) {
    const productId = id;
    let updatedCart: BasketItem[];
    const productExists = cartItems.find(
      (cartItem: BasketItem) => cartItem.id === productId
    );

    if (productExists) {
      updatedCart = cartItems.map((cartItem) =>
        cartItem.id === productId ? { ...cartItem, quantity } : cartItem
      );
    } else {
      updatedCart = [...cartItems, { id, name, image, price, quantity }];
    }
    updateCartItems(updatedCart);
  }

  return (
    <Product key={id}>
      <ImageContainer>
        <Quantity>{maxQuantity}</Quantity>
        <Image source={{ uri: image }} />
      </ImageContainer>
      <ItemTitle>{name}</ItemTitle>
      <ItemContainer>
        <ItemQuantify>
          <InputSpinner
            max={maxQuantity}
            min={0}
            step={1}
            colorMax={colorWhite}
            colorMin={colorWhite}
            rounded={false}
            value={quantity}
            onChange={(getQuantity: number) => {
              updateCartItem({ id, name, price, image, quantity: getQuantity });
            }}
            inputStyle={{ width: 20 }}
            style={{ width: normalizeLimit(50) }}
            colorRight={colorWhite}
            colorLeft={colorWhite}
            color={colorWhite}
            colorPress={colorWhite}
            textColor="#333333"
            fontSize={normalizeLimit(17)}
            buttonTextColor={ActionColor}
            buttonStyle={{ backgroundColor: "transparent" }}
          />
        </ItemQuantify>
        <Price>{formatCurrency(price / 100)}</Price>
      </ItemContainer>
    </Product>
  );
}
