import React from "react";
import { useAuth } from "../contexts/auth";
import SplashScreen from "../pages/SplashScreen";
import TotemRoutes from "./totem.routes";
import AppRoutes from "./app.routes";
import AuthRoutes from "./auth.routes";

const Routes: React.FC = () => {
  const { signed, loading, user } = useAuth();
  //se tiver dúvidas em rotas ou autenticação
  //veja: https://www.youtube.com/watch?v=KISMYYXSIX8&t=676s
  //return <AuthRoutes />; //para teste
  //return <AppRoutes />; //para teste das rotas de autenticação
  //return <ThemeProvider theme={theme}><AppRoutes /></ThemeProvider>;
  //return <ThemeProvider theme={theme}><AuthRoutes /></ThemeProvider>;

  //return  signed ? <AuthRoutes />: <AppRoutes />;

  if (loading) {
    return <SplashScreen />;
  }
  return signed ? (
    user.isTotemAccount ? (
      <TotemRoutes
        isTotemAccount={user.isTotemAccount}
        machine={user.machine}
      />
    ) : (
      <AppRoutes />
    )
  ) : (
    <AuthRoutes user={user} />
  );
};

export default Routes;
