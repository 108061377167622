import React from "react";
import { LogBox, Platform, StatusBar } from "react-native";
import { ThemeProvider } from "react-native-elements";
import "react-native-gesture-handler";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./src/contexts/auth";
import { CartProvider } from "./src/contexts/cart";
import Routes from "./src/routes/index.routes";
import LightTheme from "./src/theme/LightTheme";
import { ChatProvider } from "./src/contexts/chat";

LogBox.ignoreLogs([
  "react-native-gesture-handler",
  "EventEmitter.removeListener",
]);

export default function App() {
  return (
    <ThemeProvider theme={LightTheme}>
      {Platform.OS === "web" && (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="light"
          />
        </>
      )}
      <StatusBar barStyle="dark-content" backgroundColor="#FFF" />
      <CartProvider>
        <AuthProvider>
          <ChatProvider>
            <Routes />
          </ChatProvider>
        </AuthProvider>
      </CartProvider>
    </ThemeProvider>
  );
}
