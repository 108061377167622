
import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';
import { colorRed } from '../../utils/colors';

export const Container = styled.View`
  position: relative;
`;

export const NumberText = styled.Text`
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: ${normalizeLimit(12)}px;
  background: ${colorRed};
  width: ${normalizeLimit(22)}px;
  height: ${normalizeLimit(22)}px;
  border-radius: ${normalizeLimit(22)}px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
`;
