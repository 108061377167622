import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Button } from "react-native-elements";
import BasketInfo from "../../components/BasketInfo";
import { PAYMENT_CONFIRMED } from "../../image";
import {
  CardImage,
  Container,
  ImageContainer,
  InfoContent,
  Message,
  Title,
} from "./styles";
import userService from "../../services/userservice";
import { useAuth } from "../../contexts/auth";

const PaymentConfirmed: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { user } = useAuth();
  const TEN_SECONDS = 10 * 1000;
  const { items, total, device_failed } = route.params;
  const navigation = useNavigation();

  useEffect(() => {
    let timeoutPIN = setTimeout(async () => {
      if (user.isTotemAccount) {
        navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] });
      } else {
        navigation.reset({ index: 0, routes: [{ name: "Home" }] });
      }
    }, TEN_SECONDS);

    return () => {
      clearTimeout(timeoutPIN);
    };
  });

  return (
    <Container>
      <InfoContent>
        <ImageContainer>
          <CardImage source={PAYMENT_CONFIRMED} />
        </ImageContainer>
        <Title>Pagamento Realizado!</Title>
        <Message>
          Obrigado! Seu pagamento foi efetuado com sucesso. Agora você pode
          abrir a máquina e retirar seus produtos.
        </Message>
      </InfoContent>
      <BasketInfo items={items} total={total} />
      {device_failed && (
        <>
          <Message>
            Algum problema com a máquina? Entre em contato com o nosso suporte
          </Message>
          <Button
            type={"clear"}
            onPress={() => {
              navigation.navigate("ChatSupport");
            }}
            title="Conversar com suporte"
          />
        </>
      )}
      <Button
        onPress={() => {
          if (user.isTotemAccount) {
            navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] });
          } else {
            navigation.navigate("Home");
          }
        }}
        title="Voltar para o Início"
      />
    </Container>
  );
};

export default PaymentConfirmed;
