import { NavigationContainer } from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";
import MenuLeft from "../components/Header/MenuLeft";
import { BasketItem } from "../models/basketItem";
import { MachineIdentification } from "../models/machine";
import CardConfirmed from "../pages/MercadoPago/CardConfirmed";
import CardError from "../pages/MercadoPago/CardError";
import CardTimeout from "../pages/MercadoPago/CardTimeout";
import CreditCard from "../pages/MercadoPago/CreditCard";
import DebitCard from "../pages/MercadoPago/DebitCard";
import VoucherCard from "../pages/MercadoPago/VoucherCard";
import PaymentConfirmed from "../pages/PaymentConfirmed";
import OrdersTotem from "../pages/OrdersTotem";
import PaymentTotem from "../pages/PaymentTotem";
import Pix from "../pages/Pix";
import PixError from "../pages/PixError";
import { normalizeLimit } from "../utils/normalizeLimit";
import MachineBasketTotem from "../pages/MachineBasketTotem";

interface AppTotemRoutesProps {
  isTotemAccount?: boolean;
  machine?: MachineIdentification;
}

const Stack = createStackNavigator();

type TotemStackParamList = {
  MachineBasket: {
    machineId: string;
    machine: MachineIdentification;
  };
  OrdersTotem: undefined;
  PaymentTotem: {
    machine: MachineIdentification;
    total: number;
    items: any[];
  };
  CreditCard: {
    items: BasketItem[];
    total: number;
    machine: MachineIdentification;
  };
  DebitCard: {
    items: BasketItem[];
    total: number;
    machine: MachineIdentification;
  };
  VoucherCard: {
    items: BasketItem[];
    total: number;
    machine: MachineIdentification;
  };
  Pix: {
    items: BasketItem[];
    total: number;
    qrCodeString: string;
    qrCodeImage: string;
    paymentId: string;
    machine: MachineIdentification;
  };
  CardTimeout: { items: BasketItem[]; total: number };
  CardError: { items: BasketItem[]; total: number };
  PixError: {
    items: BasketItem[];
    total: number;
    machine: MachineIdentification;
  };
  CardConfirmed: { items: BasketItem[]; total: number };
  PaymentConfirmed: { items: BasketItem[]; total: number };
};

export type TotemRoutesStackTypes = StackNavigationProp<TotemStackParamList>;

const TotemRoutes: React.FC<AppTotemRoutesProps> = ({ machine }) => (
  <NavigationContainer>
    <Stack.Navigator
      initialRouteName="MachineBasket"
      screenOptions={{ headerMode: "screen" }}
    >
      <Stack.Screen
        name="MachineBasket"
        component={MachineBasketTotem}
        initialParams={{
          machineId: machine?.id,
          machine,
        }}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="OrdersTotem"
        component={OrdersTotem}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Lista de pedidos",
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="PaymentTotem"
        component={PaymentTotem}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Seus produtos estarão em:",
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="CreditCard"
        component={CreditCard}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="DebitCard"
        component={DebitCard}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="VoucherCard"
        component={VoucherCard}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Pix"
        component={Pix}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CardTimeout"
        component={CardTimeout}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CardError"
        component={CardError}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PixError"
        component={PixError}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CardConfirmed"
        component={CardConfirmed}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PaymentConfirmed"
        component={PaymentConfirmed}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  </NavigationContainer>
);

export default TotemRoutes;
