import React, { useEffect, useState } from "react";
import DropDownPicker from "react-native-dropdown-picker";
import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PIX, creditcardsimg } from "../../image";
import { MachineIdentification } from "../../models/machine";
import api from "../../services/api";
import { Formik } from "formik";
import { ActivityIndicator } from "react-native";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { MaskedTextInput } from "react-native-mask-text";
import * as Yup from "yup";
import MachineSelectedView from "../../components/MachineSelectedView";
import MyModal from "../../components/MyModal";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { Creditcard } from "../../models/creditcard";
import { ActionColor, colorRed } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { formatCurrency } from "../../utils/formatNumber";
import {
  isValidCC,
  isValidCPF,
  isValidCVV,
  isValidExp,
  isValidPhoneNumber,
} from "../../utils/valid";
import { useCardsChangedListener } from "../CreditCard";
import {
  BasketInfo,
  CardImage,
  CenteredView,
  Container,
  ContainerInput,
  ContainerPayment,
  CreditCardsAvaliable,
  FormContainer,
  InfoCreditCards,
  InputLabel,
  InputTextError,
  InputWrapper,
  ItemImagePix,
  PaymentLoading,
  PaymentLoadingText,
  ProductContainer,
  ProductContent,
  ProductDelete,
  ProductImage,
  ProductInfoContainer,
  ProductPrice,
  ProductTitle,
  PurchaseTotal,
  ResumeTitle,
  WrapperInput,
} from "./styles";
import { BasketItem } from "../../models/basketItem";
import CheckBox from "../../components/CheckBox";
import toCapitalize from "../../utils/toCapitalize";

type RouteParamList = {
  machine: MachineIdentification;
  total: number;
  items: any;
};

interface FormValuesCard {
  cardNumber?: number;
  cvv?: number;
  expiration?: string;
  holderName?: string;
}

interface FormValuePix {
  cpf?: string;
  phone?: string;
}

interface MethodDPIItemList {
  label: string;
  value: string;
  icon: any;
}

const CreditCardValidationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .test("test-number", "Insira um número de cartão válido", (value) =>
      isValidCC(value)
    )
    .required("Insira o número do seu cartão"),
  cvv: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "insira um código de segurança válido!", //validation message
      (value) => isValidCVV(value)
    ) // return true false based on validation
    .required("insira o código de segurança"),
  expiration: Yup.string()
    .test("test-number", "Insira uma data de expiração válida!", (value) =>
      isValidExp(value)
    )
    .required("Insira a data de expiração do cartão"),
  holderName: Yup.string().max(24).required("Insira o nome impresso no cartão"),
});

const PixFormValidationSchema = Yup.object().shape({
  cpf: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "Insira um CPF válido!", //validation message
      (value) => isValidCPF(value)
    ) // return true false based on validation
    .required("Insira o seu CPF"),
  phone: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "Insira um Número válido!", //validation message
      (value) => isValidPhoneNumber(value)
    ) // return true false based on validation
    .required("Insira seu número de telefone"),
});

const Payment: React.FC = () => {
  let updatedCart: BasketItem[];
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation();
  const { user, setContextUser } = useAuth();
  let { machine } = route.params;
  const [paymentType, setPaymentType] = useState("card");
  const [open, setOpen] = useState(false);
  const [paymentMethodDPItemList, setPaymentMethodDPItemList] = useState<
    MethodDPIItemList[]
  >([]);
  const [creditcards, setCreditCards] = useState<Creditcard[]>(
    user?.creditcards ? user?.creditcards : []
  );
  const [paymentMethodSelected, setPaymentMethodSelected] = useState("");
  const [cardType, setCardType] = useState("CreditCard");
  const [checkSaveCard, setCheckSaveCard] = useState(false);
  const [savePixData, setSavePixData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [showPixForm, setShowPixForm] = useState(false);
  const [items, setItems] = useState<BasketItem[] | []>(
    JSON.parse(JSON.stringify(route.params.items))
  );
  const [total, setTotal] = useState(route.params.total);

  async function saveCartItems(items: BasketItem[]) {
    try {
      setLoading(true);
      const params = {
        machine_id: machine.id,
        items,
      };
      api.post("/cart", { ...params });
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  async function getCreditCards() {
    try {
      setLoading(true);
      const response = await api.get("/card");
      const { cards } = response.data;
      setLoading(false);
      return cards;
    } catch (err: any) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  async function pixPayment(body: any) {
    const response = await api.post("/payment/pix", { ...body });
    const { payment } = response.data;
    const { payment_id, qr_code, qr_code_url } = payment;
    return {
      payment_id,
      qr_code,
      qr_code_url,
    };
  }

  async function registerCardPayment(body: any) {
    const response = await api.post("/payment", body);
    return response;
  }

  async function handlePixPayment({ cpf, phone }: FormValuePix) {
    try {
      if (!cpf && !phone) {
        AlertOS({
          title: "Pagamento PIX",
          message:
            "Para prosseguir com o pagamento Pix, você deve informar o CPF e Número de telefone",
          toastType: "info",
        });
        return;
      }
      setLoading(true);
      setShowPixForm(false);
      const machineId = machine.id;
      const productsList = items.map((item) => {
        return {
          product_id: item.id,
          unit_price: item.price,
          description: item.name,
          quantity: item.quantity,
        };
      });
      const userPhoneNumber = {
        country_code: "55",
        number: phone.slice(2),
        area_code: phone.slice(0, 2),
      };
      const phoneNumber = `55${userPhoneNumber.area_code}${userPhoneNumber.number}`;
      if (savePixData) {
        setContextUser({ ...user, cpf, phone: phoneNumber });
      }
      const { qr_code, payment_id, qr_code_url } = await pixPayment({
        machine_id: machineId,
        products_purchase: productsList,
        cpf,
        phone_number: userPhoneNumber,
        save: savePixData,
      });
      setLoading(false);

      navigation.navigate("Pix", {
        items,
        total,
        qrCodeString: qr_code,
        qrCodeImage: qr_code_url,
        paymentId: payment_id,
        machine,
      });
    } catch (err: any) {
      setLoading(false);
      AlertOS({
        title: "Não foi possível realizar o pagamento",
        message: "Problemas na conexão. Tente novamente",
        toastType: "info",
      });
    }
  }

  async function deleteCartItems(machineId: string) {
    try {
      await api.delete("/cart", { params: { machine_id: machineId } });
    } catch (err) {
      console.log("ERROR: ", err);
    }
  }

  async function handleCardPaymentGeneric(card: FormValuesCard) {
    try {
      if (!loading) {
        setLoading(true);
        const machineId = machine.id;
        const productsList = items.map((item) => {
          return {
            product_id: item.id,
            unit_price: item.price,
            description: item.name,
            quantity: item.quantity,
          };
        });
        const expiration =
          card?.expiration?.substring(0, 2) +
          "/20" +
          card?.expiration?.substring(2, 4);
        await registerCardPayment({
          machine_id: machineId,
          products_purchase: productsList,
          card: {
            cardHolder: card.holderName,
            cardNumber: card.cardNumber,
            cvv: card.cvv,
            expirationDate: expiration,
            save: checkSaveCard,
            cardType: cardType,
          },
        });
        await deleteCartItems(machineId);
        navigation.navigate("PaymentConfirmed", { items, total });
        setShowCardForm(false);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      AlertOS({
        title: "Não foi possível realizar o pagamento",
        message:
          "Cadastre um cartão de crédito válido com limite suficiente ou ligue para o seu banco",
        toastType: "error",
      });
    }
  }

  async function handleCardPayment() {
    try {
      if (!loading) {
        setLoading(true);
        const machineId = machine.id;
        const productsList = items.map((item) => {
          return {
            product_id: item.id,
            unit_price: item.price,
            description: item.name,
            quantity: item.quantity,
          };
        });
        if (!paymentMethodSelected) return;
        const cardSelected = user.creditcards.find(
          (card) => card.card_id == paymentMethodSelected
        );
        if (!cardSelected) return;
        const response = await registerCardPayment({
          card: {
            card_id: cardSelected?.card_id,
          },
          machine_id: machineId,
          products_purchase: productsList,
        });
        const { device_failed } = response.data.payment;
        await deleteCartItems(machineId);
        navigation.navigate("PaymentConfirmed", {
          items,
          total,
          device_failed,
        });
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response) {
        let title = "Não foi possível realizar o pagamento";
        let message =
          "Cadastre um cartão de crédito válido com limite suficiente ou ligue para o seu banco";
        if (err.response.data.title == "Falha ao Abrir Dispositivo") {
          title = "A forma de pagamento selecionada não está disponível";
          message = "Problemas na conexão. Tente novamente";
        }
        AlertOS({
          title,
          message,
          toastType: "info",
        });
      } else {
        AlertOS({
          title: "Não foi possível realizar o pagamento",
          message: "Entre em contato com o suporte",
          toastType: "error",
        });
      }
    }
  }

  async function handlePayment() {
    if (!total) return;
    switch (paymentType) {
      case "pix":
        setShowPixForm(true);
        break;
      case "card":
        handleCardPayment();
        break;
      default:
        AlertOS({
          title: "Qual forma de pagamento?",
          message: "Selecione uma forma de pagamento para prosseguir",
          toastType: "info",
        });
        break;
    }
    return;
  }

  function loadDropDownData(cards: Creditcard[]) {
    if (cards) {
      const cardList: any = [];
      let cardDefault: Creditcard;
      cards.forEach((card: Creditcard) => {
        if (!cardDefault || card.default) {
          cardDefault = card;
          setPaymentMethodSelected(cardDefault.card_id);
        }
        cardList.push({
          label: `XXXX XXXX XXXX ${card.final}`,
          value: card.card_id,
          idPaymentMethod: card.card_id,
          containerStyle: {
            paddingLeft: 20,
          },
          icon: () => <CardImage source={creditcardsimg(card.card_brand)} />,
        });
      });
      setPaymentMethodDPItemList([
        {
          label: "Cartões",
          value: "cartoes",
          idPaymentMethod: "cartoes",
          disabled: "disabled",
          labelStyle: {
            fontWeight: "600",
          },
        },
        ...cardList,
        {
          label: "Adicionar novo cartão ",
          value: "add_card",
          idPaymentMethod: "add_card",
          labelStyle: {
            fontWeight: "600",
            color: ActionColor,
            textAlign: "center",
          },
          containerStyle: {
            paddingTop: 20,
            paddingBottom: 0,
            border: "none",
          },
        },
        {
          label: "Outros",
          value: "outros",
          idPaymentMethod: "outros",
          labelStyle: {
            fontWeight: "600",
          },
          disabled: "disabled",
        },
        {
          label: "Pagamento Pix",
          value: "pix",
          idPaymentMethod: "PixPayment",
          containerStyle: {
            paddingLeft: 20,
          },
          icon: () => <ItemImagePix source={PIX} />,
        },
      ]);
    }
  }

  function handleDeleteItem(item: BasketItem) {
    const productId = item.id;
    updatedCart = items
      .map((cartItem) => {
        if (cartItem.id === productId) {
          cartItem.quantity -= 1;
        }
        return cartItem;
      })
      .filter((cartItem) => cartItem.quantity > 0);
    saveCartItems(updatedCart);
    setTotal(
      updatedCart.reduce((acc, cur) => {
        acc += cur.price * cur.quantity;
        return acc;
      }, 0)
    );
    setItems(updatedCart);
  }

  useEffect(() => {
    const loadAndSetCreditCards = async () => {
      const cards = await getCreditCards();
      const updatedUser = { ...user };
      updatedUser.creditcards = cards;
      const defaultCard = cards.find((card: Creditcard) => card.default);
      updatedUser.defaultCard = defaultCard?.card_id ?? "";
      setCreditCards(cards);
      loadDropDownData(cards);
      setContextUser(updatedUser);
    };
    loadAndSetCreditCards();
  }, []);

  useEffect(() => {
    loadDropDownData(creditcards);
  }, [creditcards]);
  // responde a eventos de alteração de cartão
  useCardsChangedListener(({ cards: cards }) => {
    setCreditCards(cards);
    loadDropDownData(cards);
  }, []);

  return (
    <Container>
      <MyModal visible={showCardForm}>
        <CenteredView>
          <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
            <Formik
              initialValues={{
                cardNumber: undefined,
                cvv: undefined,
                expiration: undefined,
                holderName: "",
              }}
              validationSchema={CreditCardValidationSchema}
              validateOnChange={false}
              onSubmit={(values) => {
                handleCardPaymentGeneric({
                  holderName: values.holderName,
                  cardNumber: values.cardNumber,
                  cvv: values.cvv,
                  expiration: values.expiration,
                });
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <FormContainer>
                  <InfoCreditCards>
                    <CreditCardsAvaliable>
                      Insira as informações do seu cartão
                    </CreditCardsAvaliable>
                  </InfoCreditCards>

                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="credit-card"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="Número do cartão"
                    placeholder="0000 0000 0000 0000"
                    maxLength={16}
                    keyboardType="numeric"
                    secureTextEntry={false}
                    autoCapitalize="none"
                    autoCorrect={false}
                    returnKeyType="send"
                    errorMessage={errors.cardNumber}
                    onChangeText={handleChange("cardNumber")}
                    onBlur={handleBlur("cardNumber")}
                    onSubmitEditing={() => handleSubmit()}
                    containerStyle={{
                      paddingBottom: dinamicPadding(
                        errors.cardNumber,
                        touched.cardNumber
                      ),
                    }}
                    autoComplete="off"
                  />

                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="calendar-today"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="Data Expiração"
                    placeholder="MMAA"
                    maxLength={4}
                    keyboardType="numeric"
                    secureTextEntry={false}
                    autoCapitalize="none"
                    autoCorrect={false}
                    returnKeyType="send"
                    errorMessage={errors.expiration}
                    onChangeText={handleChange("expiration")}
                    onBlur={handleBlur("expiration")}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete="off"
                  />

                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="lock"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="CVV"
                    placeholder="***"
                    maxLength={4}
                    keyboardType="numeric"
                    secureTextEntry={true}
                    autoCapitalize="none"
                    autoCorrect={false}
                    returnKeyType="send"
                    errorMessage={errors.cvv}
                    onChangeText={handleChange("cvv")}
                    onBlur={handleBlur("cvv")}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete="off"
                  />

                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="person"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="Nome do titular"
                    placeholder="Nome impresso no cartão"
                    maxLength={24}
                    keyboardType="default"
                    secureTextEntry={false}
                    autoCapitalize="none"
                    autoCorrect={false}
                    returnKeyType="send"
                    errorMessage={errors.holderName}
                    value={values.holderName}
                    onChangeText={handleChange("holderName")}
                    onBlur={handleBlur("holderName")}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete="off"
                  />
                  <InputWrapper>
                    <CheckBox
                      title="Salvar Cartão?"
                      onPress={() => setCheckSaveCard(!checkSaveCard)}
                      isChecked={checkSaveCard}
                    />
                  </InputWrapper>
                  {/* <InputColumnGroup>
                    <CheckBox
                      title="Crédito"
                      onPress={() =>
                        setCardType(
                          cardType == "CreditCard" ? "DebitCard" : "CreditCard"
                        )
                      }
                      isChecked={cardType == "CreditCard" ? true : false}
                    />
                  </InputColumnGroup> */}
                  <Button
                    title="Pagar"
                    loading={loading}
                    onPress={() => handleSubmit()}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                  <Button
                    title="Fechar"
                    type="clear"
                    loading={loading}
                    onPress={() => setShowCardForm(false)}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                </FormContainer>
              )}
            </Formik>
          </KeyboardAwareScrollView>
        </CenteredView>
      </MyModal>

      <MyModal visible={showPixForm}>
        <CenteredView>
          <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
            <Formik
              initialValues={{
                cpf: user.cpf,
                phone: user.phone?.substring(2),
              }}
              validationSchema={PixFormValidationSchema}
              validateOnChange={true}
              onSubmit={(values) => {
                handlePixPayment({ cpf: values.cpf, phone: values.phone });
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <FormContainer>
                  <InfoCreditCards>
                    <CreditCardsAvaliable>
                      Informe seu CPF e Número de telefone para prosseguir com o
                      pagamento Via Pix
                    </CreditCardsAvaliable>
                  </InfoCreditCards>

                  <WrapperInput>
                    <InputLabel>Telefone</InputLabel>
                    <ContainerInput>
                      <MaterialIcons
                        color={ActionColor}
                        name="phone"
                        size={24}
                        style={{
                          marginRight: 14,
                        }}
                      />
                      <MaskedTextInput
                        style={{
                          flex: 1,
                          fontSize: 18,
                          paddingVertical: 8,
                        }}
                        mask="(99) 99999-9999"
                        placeholder="(99) 99999-9999"
                        placeholderTextColor={"rgb(134, 147, 158)"}
                        keyboardType="phone-pad"
                        onChangeText={(text: string, rawText: string): void => {
                          handleChange("phone")(rawText);
                        }}
                        onBlur={handleBlur("phone")}
                        maxLength={15}
                        value={values.phone}
                        returnKeyType="next"
                        autoCapitalize="none"
                        autoCorrect={false}
                        onSubmitEditing={() => handleSubmit()}
                        autoComplete="off"
                      />
                    </ContainerInput>
                    <InputTextError
                      style={{
                        paddingBottom: dinamicPadding(
                          errors.phone,
                          touched.phone
                        ),
                      }}
                    >
                      {errors.phone}
                    </InputTextError>
                  </WrapperInput>

                  <WrapperInput>
                    <InputLabel>Cpf</InputLabel>
                    <ContainerInput>
                      <MaterialIcons
                        color={ActionColor}
                        name="person"
                        size={24}
                        style={{
                          marginRight: 14,
                        }}
                      />
                      <MaskedTextInput
                        style={{
                          flex: 1,
                          fontSize: 18,
                          paddingVertical: 8,
                        }}
                        mask="999.999.999-99"
                        keyboardType="numeric"
                        placeholder="999.999.999-99"
                        placeholderTextColor={"rgb(134, 147, 158)"}
                        onChangeText={(text: string, rawText: string) => {
                          handleChange("cpf")(rawText);
                        }}
                        value={values.cpf}
                        maxLength={14}
                        autoCapitalize="none"
                        returnKeyType="send"
                        autoCorrect={false}
                        onSubmitEditing={() => handleSubmit()}
                        autoComplete="off"
                      />
                    </ContainerInput>
                    <InputTextError
                      style={{
                        paddingBottom: dinamicPadding(errors.cpf, touched.cpf),
                      }}
                    >
                      {errors.cpf}
                    </InputTextError>
                  </WrapperInput>
                  <InputWrapper>
                    <CheckBox
                      title="Salvar?"
                      onPress={() => setSavePixData(!savePixData)}
                      isChecked={savePixData}
                    />
                  </InputWrapper>
                  <Button
                    title="Concluído"
                    loading={loading}
                    onPress={() => handleSubmit()}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                  <Button
                    title="Fechar"
                    type="clear"
                    loading={loading}
                    onPress={() => setShowPixForm(false)}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                </FormContainer>
              )}
            </Formik>
          </KeyboardAwareScrollView>
        </CenteredView>
      </MyModal>

      <MachineSelectedView machine={machine} />
      <ContainerPayment style={{ zIndex: 10 }}>
        <InfoCreditCards>
          <CreditCardsAvaliable>Forma de pagamento</CreditCardsAvaliable>
        </InfoCreditCards>
        {loading ? (
          <PaymentLoading>
            <ActivityIndicator size="small" color={ActionColor} />
            <PaymentLoadingText>
              Carregando formas de pagamento...
            </PaymentLoadingText>
          </PaymentLoading>
        ) : (
          <DropDownPicker
            style={{
              flex: 1,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              paddingHorizontal: 15,
              paddingVertical: 15,
              borderWidth: 1,
              borderColor:
                paymentType == "card" ? ActionColor : "rgba(0,0,0,.1)",
              borderRadius: 5,
            }}
            listItemContainerStyle={{
              flex: 1,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              paddingHorizontal: 12,
              paddingVertical: 12,
              borderBottomWidth: 1,
              borderColor: "rgba(0,0,0,.1)",
            }}
            dropDownContainerStyle={{
              borderColor: "rgba(0,0,0,.1)",
            }}
            placeholder="Selecione uma forma de pagamento"
            open={open}
            value={paymentMethodSelected}
            items={paymentMethodDPItemList}
            setOpen={setOpen}
            setValue={setPaymentMethodSelected}
            onChangeValue={(paymentTypeValue) => {
              if (paymentTypeValue?.toLowerCase() == "pix") {
                setPaymentType("pix");
              } else if (paymentTypeValue?.toLowerCase() == "add_card") {
                setShowCardForm(true);
                setPaymentMethodSelected("");
              } else if (paymentTypeValue) {
                setPaymentType("card");
              } else {
                setPaymentType("");
              }
            }}
            // TickIconComponent={() => (
            //   <CheckBox
            //     containerStyle={{
            //       margin: 0,
            //       padding: 0,
            //     }}
            //     style={{
            //       margin: 0,
            //       padding: 0,
            //     }}
            //     center
            //     checkedIcon="dot-circle-o"
            //     uncheckedIcon="circle-o"
            //     checked={true}
            //   />
            // )}
            setItems={setPaymentMethodDPItemList}
            itemKey="idPaymentMethod"
            theme="LIGHT"
            multiple={false}
            mode="SIMPLE"
            listMode="SCROLLVIEW"
            extendableBadgeContainer={true}
            language="PT"
          />
        )}
      </ContainerPayment>
      <BasketInfo>
        <ResumeTitle>Resumo da compra</ResumeTitle>
        {items.map((item: BasketItem) => (
          <ProductContainer key={item.id}>
            <ProductInfoContainer>
              <ProductImage source={{ uri: item.image }} />
              <ProductContent>
                <ProductTitle ItemTitle>{toCapitalize(item.name)}</ProductTitle>
                <ProductPrice>
                  {item.quantity}x {formatCurrency(item.price / 100)}
                </ProductPrice>
              </ProductContent>
            </ProductInfoContainer>
            <ProductDelete>
              <MaterialIcons
                onPress={() => handleDeleteItem(item)}
                name="delete"
                color={colorRed}
                size={24}
              />
            </ProductDelete>
          </ProductContainer>
        ))}
        <PurchaseTotal>Total {formatCurrency(total / 100)}</PurchaseTotal>
      </BasketInfo>
      <Button
        title="Confirmar Pagamento"
        loading={loading}
        onPress={handlePayment}
      />
    </Container>
  );
};

export default Payment;
