import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Button } from "react-native-elements";
import { PIX_IMAGE_ERROR } from "../../image";
import {
  AppInfo,
  Container,
  InfoContent,
  Message,
  PixImage,
  Title,
} from "./styles";

type RouteParamList = {
  machine: MachineIdentification;
  total: number;
  items: BasketItem[];
};

import BasketInfo from "../../components/BasketInfo";
import { useAuth } from "../../contexts/auth";
import { BasketItem } from "../../models/basketItem";
import { MachineIdentification } from "../../models/machine";
import { TotemRoutesStackTypes } from "../../routes/totem.routes";

const PixError: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { user } = useAuth();
  const TEN_SECONDS = 10 * 1000;
  const { total, items, machine } = route.params;
  const navigation = useNavigation<TotemRoutesStackTypes>();

  useEffect(() => {
    setTimeout(async () => {
      if (user.isTotemAccount) {
        navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] });
      }
    }, TEN_SECONDS);
  });

  return (
    <Container>
      <InfoContent>
        <AppInfo>
          <PixImage source={PIX_IMAGE_ERROR} />
        </AppInfo>
        <Title>Pagamento não realizado</Title>
        <Message>
          O pagamento via Pix não foi confirmado. Que tal tentar fazer seu
          pedido novamente?
        </Message>
        <BasketInfo total={total} items={items} />
      </InfoContent>
      {user.isTotemAccount ? (
        <Button
          onPress={() =>
            navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] })
          }
          title="Voltar para o Carrinho"
        />
      ) : (
        <Button
          onPress={() =>
            navigation.navigate("Payment", {
              machine,
              total,
              items,
            })
          }
          title="Formas de pagamento"
        />
      )}
    </Container>
  );
};

export default PixError;
