import { formatCurrency } from "../../utils/formatNumber";
import toCapitalize from "../../utils/toCapitalize";
import {
  Container,
  ProductInfo,
  ProductName,
  ProductQuantity,
  ProductsContainer,
  ProductTotal,
  ResumeContainer,
  ResumeTitle,
  TotalContent,
  TotalTitle,
  TotalValue,
} from "./styles";

interface BasketItem {
  id: string;
  name: string;
  quantity: number;
  price: number;
}

interface BasketInfoProps {
  items: BasketItem[];
  total: number;
}

export default function BasketInfo({ items, total }: BasketInfoProps) {
  return (
    <Container>
      <ResumeContainer>
        <ResumeTitle>Resumo da compra</ResumeTitle>
        {items.map(({ id, name, quantity, price }) => (
          <ProductsContainer key={id}>
            <ProductInfo>
              <ProductQuantity>{quantity}x</ProductQuantity>
              <ProductName>{toCapitalize(name)}</ProductName>
            </ProductInfo>
            <ProductTotal>
              {formatCurrency((quantity * price) / 100)}
            </ProductTotal>
          </ProductsContainer>
        ))}
      </ResumeContainer>
      <TotalContent>
        <TotalTitle>Total</TotalTitle>
        <TotalValue>{formatCurrency(total / 100)}</TotalValue>
      </TotalContent>
    </Container>
  );
}
