import styled from 'styled-components/native';
import { colorBlack, colorGray } from '../../../utils/colors';
import { normalizeLimit } from '../../../utils/normalizeLimit';

export const Container = styled.ScrollView`
  flex: 1;
  margin: 0;
  background: #FFFFFF;
  padding: ${normalizeLimit(20)}px ${normalizeLimit(15)}px;
  color: ${colorGray};
`;

export const CardContent = styled.View`
  margin: ${normalizeLimit(24)}px 0;
`;

export const ImageContainer = styled.View`
  justify-content: center;
  align-items: center;
`;

export const CardImage = styled.Image`
  width: ${normalizeLimit(420)}px;
  height: ${normalizeLimit(420)}px;
`;

export const Title = styled.Text`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${colorBlack};
  margin: ${normalizeLimit(24)}px 0 ${normalizeLimit(8)}px 0;
`;

export const Message = styled.Text`
  font-size:${normalizeLimit(16)}px;
  text-align: center;
  color: ${colorGray};
`;
