import { MaterialIcons } from "@expo/vector-icons";
import { Container, LabelCheckBox } from "./styles";
import { ActionColor } from "../../utils/colors";

interface CheckBoxProps {
  title: string;
  onPress: () => void;
  isChecked: boolean;
}

export default function CheckBox({ title, onPress, isChecked }: CheckBoxProps) {
  return (
    <Container>
      <LabelCheckBox>{title}</LabelCheckBox>
      <MaterialIcons
        onPress={onPress}
        name={isChecked ? "check-box" : "check-box-outline-blank"}
        size={24}
        color={ActionColor}
      />
    </Container>
  );
}
