import AsyncStorage from '@react-native-async-storage/async-storage';
import { User } from '../models/user';

const userService = {
    getLogedUser: async (): Promise<User | null> => {
        const storegedUser = await AsyncStorage.getItem("@Auth:user");
        return (storegedUser) ? JSON.parse(storegedUser) : null;
    },
    setLogedUser: async (user: User) => {
        await AsyncStorage.setItem("@Auth:user", JSON.stringify(user));
    },

    getLogedToken: async (): Promise<string | null> => {
        const storegedToken = await AsyncStorage.getItem("@Auth:token");
        return (storegedToken) ? storegedToken : null;
    },
    setLogedToken: async (token: string) => {
        await AsyncStorage.setItem("@Auth:token", token);
    },

    getRefreshToken: async (): Promise<string | null> => {
        const storegedToken = await AsyncStorage.getItem("@Auth:refreshtoken");
        return (storegedToken) ? JSON.parse(storegedToken) : null;
    },
    setRefreshToken: async (token: string) => {
        await AsyncStorage.setItem("@Auth:refreshtoken", JSON.stringify(token));
    },

    clear: async () => {
        await AsyncStorage.removeItem('@Auth:user');
        await AsyncStorage.removeItem('@Auth:token');
        await AsyncStorage.removeItem('@Auth:refreshtoken');
        await AsyncStorage.removeItem('@Chat:messages')
        await AsyncStorage.removeItem('@Chat:channelId')
    }
};

export default userService;