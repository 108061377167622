import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';


export const Container = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const LabelCheckBox = styled.Text`
  color: #333;
  font-weight: bold;
  margin-right: ${normalizeLimit(8)}px;
`;